<template>
    <div>
        <div class="page-header hidden-sm-and-down">
            <el-page-header @back="$router.go(-1)" content="项目统计" />
        </div>
        <searchForm style="margin: 15px 0" />
        <div style="margin:25px 0">
            <el-row :gutter="20">
                <el-col :span="6">
                    <div>
                        <el-statistic group-separator="," :precision="2" :value="value2" :title="title"></el-statistic>
                    </div>
                </el-col>
                <el-col :span="6">
                    <div>
                        <el-statistic title="男女比">
                            <template slot="formatter"> 456/2 </template>
                        </el-statistic>
                    </div>
                </el-col>
                <el-col :span="6">
                    <div>
                        <el-statistic group-separator="," :precision="2" decimal-separator="." :value="value1"
                            :title="title">
                            <template slot="prefix">
                                <i class="el-icon-s-flag" style="color: red"></i>
                            </template>
                            <template slot="suffix">
                                <i class="el-icon-s-flag" style="color: blue"></i>
                            </template>
                        </el-statistic>
                    </div>
                </el-col>
                <el-col :span="6">
                    <div>
                        <el-statistic :value="like ? 521 : 520" title="Feedback">
                            <template slot="suffix">
                                <span @click="like = !like" class="like">
                                    <i class="el-icon-star-on" style="color:red" v-show="!!like"></i>
                                    <i class="el-icon-star-off" v-show="!like"></i>
                                </span>
                            </template>
                        </el-statistic>
                    </div>
                </el-col>
            </el-row>
        </div>
        <el-row :gutter="10" style="margin:0">
            <el-col :span="6">
                <el-card class="box-card" shadow="hover">
                    <div slot="header" class="clearfix">
                        <span>专业分布</span>
                        <el-button style="float: right; padding: 3px 0" type="text">操作按钮</el-button>
                    </div>
                    <div v-for="o in 4" :key="o" class="text item">
                        {{ '列表内容 ' + o }}
                    </div>
                </el-card>
            </el-col>
            <el-col :span="6">
                <el-card class="box-card" shadow="hover">
                    <div slot="header" class="clearfix">
                        <span>职称分布</span>
                        <el-button style="float: right; padding: 3px 0" type="text">操作按钮</el-button>
                    </div>
                    <div v-for="o in 4" :key="o" class="text item">
                        {{ '列表内容 ' + o }}
                    </div>
                </el-card>
            </el-col>
            <el-col :span="6">
                <el-card class="box-card" shadow="hover">
                    <div slot="header" class="clearfix">
                        <span>学历分布</span>
                        <el-button style="float: right; padding: 3px 0" type="text">操作按钮</el-button>
                    </div>
                    <div v-for="o in 4" :key="o" class="text item">
                        {{ '列表内容 ' + o }}
                    </div>
                </el-card>
            </el-col>
            <el-col :span="6">
                <el-card class="box-card" shadow="hover">
                    <div slot="header" class="clearfix">
                        <span>年龄分布</span>
                        <el-button style="float: right; padding: 3px 0" type="text">操作按钮</el-button>
                    </div>
                    <div v-for="o in 4" :key="o" class="text item">
                        {{ '列表内容 ' + o }}
                    </div>
                </el-card>
            </el-col>
        </el-row>
        <el-row :gutter="10" style="margin:15px 0">
            <el-col :span="24">
                <ganttChart />
            </el-col>
        </el-row>
    </div>
</template>

<script>
import searchForm from './widgets/searchForm.vue';
export default {
    components: {
        searchForm
    },
    data() {
        return {
            like: true,
            value1: 4154.564,
            value2: 2222,
            title: '今年的增长',
        };
    },
};
</script>
<style lang="scss">
.like {
    cursor: pointer;
    font-size: 25px;
    display: inline-block;
}
</style>